import { Link } from "gatsby"
import React from "react"
import Layout from '../components/layout';
import "../styles/scss/common/all-list.scss"
import { graphql } from 'gatsby';

const AllBanksPage = ({ data }) => {
	const seoData = {
		'title' : 'All banks page', 
		'description' : 'All banks page', 
		'canonicalUrl' : '/all-banks/', 
		'robots' : 'index,follow'
		};
	
	return(
  <Layout page={seoData} >
    <section className="container all-list-wrapper">
      <h1>All banks</h1>
      <div>
        {data.hc.banks.map(bank => (
          <h2 key={bank.id}> <Link to={bank.page.url}>{bank.name}</Link></h2>
        ))}
      </div>
    </section>
  </Layout>
)
}

export default AllBanksPage

export const allBanks = graphql`
  query {
    hc {
      banks (where : {OR : [{ disabled : false}, {disabled : null}]}, orderBy: name_ASC) {
        id
        name
		page {url gatsbyUrl}
       }
    }
  }
`;
